// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardCompetences_card__BU-jl img{
  width:40px;
  height: 40px;
}

.CardCompetences_card__BU-jl p{
  color: white;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.CardCompetences_card__BU-jl{
  padding:12px 8px;
  border: 1px solid white;
  border-radius: 8px;
  width: 84px;

}
`, "",{"version":3,"sources":["webpack://./src/components/CardCompetences/CardCompetences.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,SAAS;EACT,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,kBAAkB;EAClB,WAAW;;AAEb","sourcesContent":[".card img{\n  width:40px;\n  height: 40px;\n}\n\n.card p{\n  color: white;\n  margin: 0;\n  padding: 0;\n  font-size: 16px;\n}\n\n.card{\n  padding:12px 8px;\n  border: 1px solid white;\n  border-radius: 8px;\n  width: 84px;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `CardCompetences_card__BU-jl`
};
export default ___CSS_LOADER_EXPORT___;
