// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Title_TitleText__QuiRI{
padding: 8px 16px;
background-color: white;
color: red;
width: 200px;
font-size: 24px;
display: flex;
align-items: center;
justify-content: center;


}

.Title_square__xDz\\+7{
  width: 30px;
  height: 100%;
  border: 1px solid white;
}

.Title_squareMedium__eHR\\+G{
  width: 70px;
  height: 100%;
  border: 1px solid white;
}

.Title_squareLonger__pgDWQ{
  width: 120px;
  height: 100%;
  border: 1px solid white;
}

.Title_title__mrNq5{
  padding-left: 50px;
  padding-top: 40px;
  background-color: transparent;
  display: flex;
  justify-content: start;
  gap: 8px;
  height:40px;
}


@media screen and (max-width: 800px) {
  .Title_squareMedium__eHR\\+G{
    display: none;
  }

  .Title_squareLonger__pgDWQ{
    display: none;
  }

  .Title_square__xDz\\+7{
    display: none;
  }
  }
`, "",{"version":3,"sources":["webpack://./src/components/Title/Title.module.css"],"names":[],"mappings":"AAAA;AACA,iBAAiB;AACjB,uBAAuB;AACvB,UAAU;AACV,YAAY;AACZ,eAAe;AACf,aAAa;AACb,mBAAmB;AACnB,uBAAuB;;;AAGvB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,6BAA6B;EAC7B,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,WAAW;AACb;;;AAGA;EACE;IACE,aAAa;EACf;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,aAAa;EACf;EACA","sourcesContent":[".TitleText{\npadding: 8px 16px;\nbackground-color: white;\ncolor: red;\nwidth: 200px;\nfont-size: 24px;\ndisplay: flex;\nalign-items: center;\njustify-content: center;\n\n\n}\n\n.square{\n  width: 30px;\n  height: 100%;\n  border: 1px solid white;\n}\n\n.squareMedium{\n  width: 70px;\n  height: 100%;\n  border: 1px solid white;\n}\n\n.squareLonger{\n  width: 120px;\n  height: 100%;\n  border: 1px solid white;\n}\n\n.title{\n  padding-left: 50px;\n  padding-top: 40px;\n  background-color: transparent;\n  display: flex;\n  justify-content: start;\n  gap: 8px;\n  height:40px;\n}\n\n\n@media screen and (max-width: 800px) {\n  .squareMedium{\n    display: none;\n  }\n\n  .squareLonger{\n    display: none;\n  }\n\n  .square{\n    display: none;\n  }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TitleText": `Title_TitleText__QuiRI`,
	"square": `Title_square__xDz+7`,
	"squareMedium": `Title_squareMedium__eHR+G`,
	"squareLonger": `Title_squareLonger__pgDWQ`,
	"title": `Title_title__mrNq5`
};
export default ___CSS_LOADER_EXPORT___;
