// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/topography_white.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Portfolio_portfolio__2ONxQ{
  width: 100vw;
  background: linear-gradient(90deg, rgb(133,46,54) 0%, rgb(199,49,64) 50%, rgb(133,46,54) 100%);
position: relative;
padding-bottom: 50px;
}

.Portfolio_portfolio__2ONxQ::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center/cover;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.1;
}


.Portfolio_container__rN7dw{
  width: 100vw;
  margin: 0 auto;
}

.Portfolio_cards__ttDR4{
  margin: 0 auto;
  width:80%;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap:32px;
  z-index: 10;
  position: relative;
  align-items: stretch;
}

@media screen and (max-width: 800px) {
  .Portfolio_cards__ttDR4{
flex-direction: column;

  }
  }
`, "",{"version":3,"sources":["webpack://./src/components/Portfolio/Portfolio.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,8FAA8F;AAChG,kBAAkB;AAClB,oBAAoB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,0EAA2E;EAC3E,WAAW;EACX,YAAY;EACZ,UAAU;EACV,YAAY;AACd;;;AAGA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,SAAS;EACT,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,QAAQ;EACR,WAAW;EACX,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE;AACF,sBAAsB;;EAEpB;EACA","sourcesContent":[".portfolio{\n  width: 100vw;\n  background: linear-gradient(90deg, rgb(133,46,54) 0%, rgb(199,49,64) 50%, rgb(133,46,54) 100%);\nposition: relative;\npadding-bottom: 50px;\n}\n\n.portfolio::before{\n  content: \"\";\n  position: absolute;\n  top: 0;\n  left: 0;\n  background: url(\"../../assets/topography_white.png\") no-repeat center/cover;\n  width: 100%;\n  height: 100%;\n  z-index: 0;\n  opacity: 0.1;\n}\n\n\n.container{\n  width: 100vw;\n  margin: 0 auto;\n}\n\n.cards{\n  margin: 0 auto;\n  width:80%;\n  display: flex;\n  justify-content: start;\n  flex-wrap: wrap;\n  gap:32px;\n  z-index: 10;\n  position: relative;\n  align-items: stretch;\n}\n\n@media screen and (max-width: 800px) {\n  .cards{\nflex-direction: column;\n\n  }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"portfolio": `Portfolio_portfolio__2ONxQ`,
	"container": `Portfolio_container__rN7dw`,
	"cards": `Portfolio_cards__ttDR4`
};
export default ___CSS_LOADER_EXPORT___;
