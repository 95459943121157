import React from "react";
import styles from "./Footer.module.css";


const Footer = () => {
  return (
    <div className={styles.features}>

    </div>
  );
};

export default Footer;
